<template>
    <div class="bg-form">
        <div class="container mb-5">
            <div class="sub-container">
                <div class="head-form mb-4 d-md-block d-sm-none d-none">
                    <div v-if="bookingCategory" class="top-title">
                        <span>Booking for</span>
                        <h4>{{ bookingCategory }}</h4>
                    </div>
                    <div v-if="invoice" class="bottom-info row">
                        <div class="col-12 text-center">
                            <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 3.5H10.5C10.6326 3.5 10.7598 3.55268 10.8536 3.64645C10.9473 3.74021 11 3.86739 11 4V10C11 10.1326 10.9473 10.2598 10.8536 10.3536C10.7598 10.4473 10.6326 10.5 10.5 10.5H1.5C1.36739 10.5 1.24021 10.4473 1.14645 10.3536C1.05268 10.2598 1 10.1326 1 10V2C1 1.86739 1.05268 1.74021 1.14645 1.64645C1.24021 1.55268 1.36739 1.5 1.5 1.5H9V3.5ZM2 4.5V9.5H10V4.5H2ZM2 2.5V3.5H8V2.5H2ZM7.5 6.5H9V7.5H7.5V6.5Z" fill="white"/>
                            </svg>
                            <span v-if="isLoading && !invoice" class="ml-2">Processing Your Booking</span>
                            <span v-else-if="!invoice.status || (invoice.status && (invoice.status == 'pending' || invoice.status == 'cancelled'))" class="ml-2">Your Invoice Is Uncompleted</span>
                            <span v-else-if="invoice.status && invoice.status == 'completed'" class="ml-2">Payment completed</span>
                        </div>
                    </div>
                </div>
                <div class="cards">
                    <div v-if="!isLoading" class="card text-center">
                        <div v-if="!invoice.status || invoice.status == 'pending' || invoice.status == 'cancelled'" class="row">
                            <div>
                                <span class="d-block w-100 mid-icon-failure"><i class="far fa-times-circle"></i></span>
                                <p>
                                    Sorry, your payment is not fully completed. Please complete your payment within 48 hours.
                                </p>
                                <small class="d-block w-100">Booking number : <u>{{ invoice.booking_form.booking_number }}</u></small>
                                <small class="d-block w-100">Invoice number : <u>{{ invoice.invoice_number }}</u></small>
                                <small class="d-block w-100 mt-3"><router-link class="text-muted" to="/customer/dashboard-booking">Go to your booking list</router-link></small>
                                <small class="d-block w-100 mt-1"><router-link class="text-muted" :to="`/home-nursing/booking/${$route.params.bookingid}/invoice/${$route.params.invoiceid}`">View current invoice</router-link></small>
                            </div>
                        </div>
                        <div v-else-if="invoice.status && invoice.status == 'completed'" class="row">
                            <span class="d-block w-100 mid-icon-success"><i class="far fa-check-circle"></i></span>
                            <p>
                                Thanks, your payment has been sucessfully completed. Please wait for Selcare's stuff to proceed with your booking.
                            </p>
                            <small class="d-block w-100">Booking number : <u>{{ invoice.booking_form.booking_number }}</u></small>
                            <small class="d-block w-100">Invoice number : <u>{{ invoice.invoice_number }}</u></small>
                            <small class="d-block w-100 mt-3"><router-link class="text-muted" to="/customer/dashboard">Go to your booking list</router-link></small>
                            <small class="d-block w-100 mt-1"><router-link class="text-muted" :to="`/home-nursing/booking/${$route.params.bookingid}/invoice/${$route.params.invoiceid}`">View current invoice</router-link></small>
                        </div>
                    </div>
                    <div v-else class="card text-center">
                        <div class="processing">
                            <div class="spinner-border spinner-border-sm"></div>
                            <span> Processing your booking ...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import { mapGetters, mapActions } from 'vuex'
import API from '../../../utils/API'
export default {
    data(){
        return {
            invoice: null,
            bookingCategory: null,
            isLoading: true
        }
    },
    computed: {
    },
    methods: {
    },
    async mounted(){
        this.isLoading = true
        let invoiceRes = await API.get(`/invoices/${this.$route.params.invoiceid}`)
        this.invoice = invoiceRes.data
        this.isLoading = false

        //set booking category
        switch (this.invoice.booking_form.category) {
            case 'nurse':
                return this.bookingCategory = 'Nurse'
            case 'physio':
                return this.bookingCategory = 'Physio'
            case 'medical_assistant':
                return this.bookingCategory = 'Medical Assistant'
            case 'care_aide':
                return this.bookingCategory = 'Care Aide'

            default:
                break;
        }

        // console.log('invoice : ', this.invoice)
    }
}
</script>

<style scoped>
.bg-form {
    /* background-color: #E5E5E5; */
    height: calc(100vh - 164px);
}

.top-title {
    text-align: center;
    background-color: #EB5757;
    color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 8px 0px;
}

.bottom-info {
    color: #ffffff;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #A91A18;
    margin: -5px 0px 0px 0px;
    padding: 15px 0px;
    border-radius: 8px;
    letter-spacing: 1px;
}
.bottom-info .text-not-yet {
    color: #EB5757;
}

.cards {

}

.card {
    border: unset;
    padding: 15px 40px;
}

.card .card-body .contents {
    padding: 15px 35px 10px 35px;
    font-size: 15px;
}

.div-separator {
    height: 2px;
    background-color: #EBEEF2;
}

.title-with-bar {
    padding: 15px 35px 10px 35px;
    background-color: #EBEEF2;
}
.title-with-green-bar {
    padding: 20px 35px 15px 35px;
    background-color: #7CC6D6;
}

span.text-big {
    font-size: 18px;
    font-weight: 500;
}

.btn.btn-add {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    background-color: #EBEEF2;
    border: 1px solid #DADEE3;
    border-radius: 6px;
    padding: 12px 25px;
}
.btn.btn-cancel {
    color: #ffffff;
    background-color: #858C94;
    padding: 15px 25px;
}
.btn.btn-submit {
    color: #ffffff;
    background-color: #7CC6D6;
    padding: 15px 100px;
}
.btn.btn-print {
    color: #ffffff;
    background-color: #eb5757;
    padding: 15px 100px;
}

.processing-overlay {
    position: absolute;
    top: 1px;
    width: 100%;
    height: 100vw;
    background: rgb(255 255 255 / 75%);
    text-align: center;
    padding: 300px 0px;
}

.mid-icon-success {
    font-size: 60px;
    color: green;
}
.mid-icon-failure {
    font-size: 60px;
    color: #d63e3c;
}

/** Media Queries */
@media (min-width: 992px) {
    .sub-container {
        padding: 0px 150px;
    }
}
@media (max-width: 766px) {
    .card .card-body {
        padding: 0px;
    }
}
@media (max-width: 570px) {
    
}
@media (max-width: 500px) {
    .card {
        padding: 15px 10px;
    }

    .btn.btn-submit {
        width: 100%;
    }
    .btn.btn-print {
        width: 100%;
        margin-bottom: 10px;
    }
}

/** Others */
.font-weight-500 {
    font-weight: 500;
}
</style>